<template>
  <div v-if="dialogFormVisible2">
    <el-dialog
      :title="titleTable"
      :visible.sync="dialogFormVisible"
      :width="'720px'"
      @close="close"
      :close-on-click-modal="false"
    >
      <el-form
        :model="deviceInfoForm"
        :rules="rules"
        ref="form"
        :label-width="'150px'"
        label-position="right"
        :class="{ 'my-form-readonly': isModelInfo }"
        size="large"
      >
        <el-row>
          <el-col :span="24" :offset="0">
            <div class="form-lable">{{ $t("Supplier.基础信息") }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              prop="supplierName"
              :label="$t('Supplier.供应商名称') + '：'"
            >
              <el-input
                size="small"
                v-model="deviceInfoForm.supplierName"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="supplierContact"
              :label="$t('Supplier.联系人') + '：'"
            >
              <el-input
                size="small"
                autocomplete="off"
                v-model="deviceInfoForm.supplierContact"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              prop="businessLicenseNo"
              :label="$t('Supplier.营业执照号码') + '：'"
            >
              <el-input
                size="small"
                v-model="deviceInfoForm.businessLicenseNo"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="supplierContactPhone"
              :label="$t('Supplier.联系人电话') + '：'"
            >
              <el-input
                size="small"
                autocomplete="off"
                v-model="deviceInfoForm.supplierContactPhone"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="remark" :label="$t('add.备注') + '：'">
              <el-input
                type="textarea"
                :rows="4"
                size="small"
                v-model="deviceInfoForm.remark"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="supplierContactEmail"
              :label="$t('Supplier.联系人邮箱') + '：'"
            >
              <el-input
                size="small"
                autocomplete="off"
                v-model="deviceInfoForm.supplierContactEmail"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" :offset="0">
            <div class="form-lable">{{ $t("Supplier.运输节点") }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24" :offset="1">
            <el-button type="primary" size="default" @click="addNode">{{
              $t("Supplier.新增节点")
            }}</el-button>
          </el-col>
          <el-col
            :span="24"
            :offset="1"
            v-for="(item, i) in supplierTransportList"
            :key="i"
          >
            <div class="nodeName">
              <div class="nodeIndex">{{ i + 1 }}</div>
              <el-input
                class="nodeInput"
                v-model="item.fromPlace"
                :placeholder="$t('Supplier.请输入节点名称')"
                size="small"
                clearable
              ></el-input>
              <el-button
                v-if="supplierTransportList.length > 1"
                type="text"
                @click="deleteNode(i)"
                style="font-size: 20px"
                icon="el-icon-delete"
                circle
              ></el-button>
            </div>
            <el-row :gutter="20" v-if="i != supplierTransportList.length - 1">
              <el-col :span="24" :offset="3">
                <div class="nodeType">
                  <el-input
                    class="nodeInput"
                    v-model="item.distance"
                    :placeholder="$t('Supplier.请输入节点距离')"
                    size="small"
                    clearable
                  ></el-input>
                  <el-select
                    v-model="item.transportType"
                    clearable
                    size="small"
                  >
                    <el-option
                      v-for="item in nodeTypeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div v-if="isModelInfo">
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="close()"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
        <div v-else>
          <el-button @click="close" size="small">{{
            $t("global.cancel")
          }}</el-button>
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="submitForm('form')"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";
import md5 from "js-md5";
import base from "@/templates/add";
import { treearr } from "@/tool";
export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {},
  data() {
    return {
      titleTable: "",
      dialogFormVisible2: false,
      dialogFormVisible: false,
      posting: false,
      isModelEdit: false,
      isModelAdd: false,
      isModelInfo: false,
      nodeTypeOptions: [
        {
          value: 0,
          label: this.$t("Supplier.公路运输"),
        },
        {
          value: 1,
          label: this.$t("Supplier.空运"),
        },
        {
          value: 2,
          label: this.$t("Supplier.海运"),
        },
      ],
      supplierTransportList: [
        {
          distance: "",
          transportType: 0,
        },
      ],
      deviceInfoForm: {
        supplierName: "",
        supplierContact: "",
        businessLicenseNo: "",
        supplierContactPhone: "",
        remark: "",
        supplierContactEmail: "",
        supplierTransportList: [],
      },
      rules: {
        supplierName: [
          {
            required: true,
            message: this.$t("global.请输入"),
            trigger: ["blur", "change"],
          },
          { pattern: /\S+/, message: this.$t("global.emptyMsg") },
        ],
        supplierContact: [
          {
            required: true,
            message: this.$t("global.请输入"),
            trigger: ["blur", "change"],
          },
          { pattern: /\S+/, message: this.$t("global.emptyMsg") },
        ],
        businessLicenseNo: [
          {
            required: true,
            message: this.$t("global.请输入"),
            trigger: ["blur", "change"],
          },
          { pattern: /\S+/, message: this.$t("global.emptyMsg") },
        ],
        supplierContactEmail: [
          {
            required: true,
            message: this.$t("global.请输入"),
            trigger: ["blur", "change"],
          },
          {
            pattern:
              /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/,
            message: this.$t("global.emailErrorMsg"),
            trigger: "blur",
          },
          { pattern: /\S+/, message: this.$t("global.emptyMsg") },
        ],
        // password: [
        //   { required: true, message: this.$t('global.请输入密码'), trigger: ['blur', 'change'] },
        //   { pattern: /^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/, message: this.$t('global.formatErr'), trigger: 'blur' },
        //   { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        // ],
        supplierContactPhone: [
          {
            required: true,
            message: this.$t("global.请输入"),
            trigger: ["blur", "change"],
          },
          {
            pattern: /^1[0-9]{10}$/,
            message: this.$t("global.formatPhoneErr"),
            trigger: "blur",
          },
          { pattern: /\S+/, message: this.$t("global.emptyMsg") },
        ],
      },
      orgListTree: [],
      roleList: [],
      copyPassword: "",
      refreshOrgLoading: false,
    };
  },
  created() {
    this.getOrg();
    api.auth.getAuthRoleAllRole().then((res) => {
      this.roleList = res.data;
    });
  },
  methods: {
    addNode() {
      var arr = this.supplierTransportList;
      arr.push({
        distance: "",
        transportType: 0,
      });
      this.supplierTransportList = arr;
    },
    deleteNode(i) {
      var arr = this.supplierTransportList;
      arr.splice(i, 1);
      this.supplierTransportList = arr;
    },
    getOrg() {
      api.organization.queryOrgTree({}).then((res) => {
        this.orgListTree = treearr(res.data);
      });
    },
    refreshOrg() {
      if (this.refreshOrgLoading) return;
      this.refreshOrgLoading = true;
      api.organization.refreshOrgTree().then((res) => {
        this.$message.success(this.$t("add.已刷新"));
        this.getOrg();

        this.refreshOrgLoading = false;
      });
    },
    show() {
      this.dialogFormVisible2 = true;
      this.$nextTick((_) => {
        this.dialogFormVisible = true;
      });
    },
    close() {
      this.isModelEdit = false;
      this.isModelAdd = false;
      this.isModelInfo = false;
      this.dialogFormVisible = false;
      this.resetForm();
      this.$nextTick((_) => {
        this.dialogFormVisible2 = false;
      });
    },
    add() {
      this.titleTable = this.$t("add.新增供应商");
      this.isModelAdd = true;
      this.supplierTransportList = [{ distance: "", transportType: 0 }];
      this.deviceInfoForm = {
        supplierName: "",
        supplierContact: "",
        businessLicenseNo: "",
        supplierContactPhone: "",
        remark: "",
        supplierContactEmail: "",
        supplierTransportList: [],
      };
      this.show();
    },
    edit(data) {
      this.titleTable = this.$t("add.编辑供应商");
      this.isModelEdit = true;
      this.deviceInfoForm = { ...data };
      this.supplierTransportList = [{}];
      if (data.supplierTransportList.length > 0) {
        this.supplierTransportList = data.supplierTransportList;
        this.supplierTransportList.push({
          fromPlace:
            data.supplierTransportList[data.supplierTransportList.length - 1]
              .toPlace,
        });
      }

      this.show();
    },
    view(data) {
      this.titleTable = this.$t("add.供应商详情");
      this.isModelInfo = true;
      this.deviceInfoForm = { ...data };
      this.show();
    },
    // 重制
    resetForm() {
      this.deviceInfoForm = {};
      this.$refs.form.resetFields();
    },
    // 提交
    submitForm(formName) {
      console.log(1);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(2);
          var sArr = this.supplierTransportList;
          var arr = [];
          for (const i in sArr) {
            if (i != sArr.length - 1) {
              arr.push({
                distance: sArr[i].distance,
                transportType: sArr[i].transportType,
                fromPlace: sArr[i].fromPlace,
                toPlace: sArr[i * 1 + 1].fromPlace,
              });
            }
          }
          this.deviceInfoForm.supplierTransportList = arr;
          console.log(3);
          if (this.isModelAdd) {
            // if (this.deviceInfoForm.password !== this.deviceInfoForm.confirmPwd) {
            //   return this.$message.warning('密码不一致')
            // }
          }
          this.posting = true;

          const query = {
            ...this.deviceInfoForm,
            id: this.deviceInfoForm.id || "",
          };
          console.log(query);
          try {
            await api.auth[
              this.isModelAdd ? "saveSupplier" : "updateSupplierById"
            ](query);
            this.posting = false;
            this.$message.success(
              this.isModelAdd
                ? this.$t("add.addOk")
                : this.$t("global.submitOk")
            );
            this.close();
            this.$parent.onSubmit();
          } catch (error) {
            this.posting = false;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.orgSelect {
  /deep/.el-form-item__content {
    display: flex;
    span {
      margin-left: 5px;
      white-space: nowrap;
      color: #0aa665;
      cursor: pointer;
      user-select: none;
    }
  }
}
.form-lable {
  padding-left: 5px;
  border-left: 2px solid #0aa665;
  font-size: 14px;
  line-height: 16px;
  font-weight: 900;
  margin-bottom: 15px;
}
.nodeName {
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: center;
  .nodeIndex {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 16px;
    text-align: center;
    line-height: 25px;
    margin-right: 15px;
    border: 1px solid rgb(97, 97, 97);
  }
}
.nodeInput {
  width: 200px;
  margin-right: 20px;
}
.nodeType {
  display: flex;
}
</style>
