<template>
  <div class="g-box g-role-add-box" v-loading="loading">
    <div class="m-form-inline-mn" data-m="add">
      <el-form
        :model="deviceInfoForm"
        class="demo-form-inline"
        size="small"
        :rules="rules"
        ref="deviceInfoForm"
        label-width="150px"
      >
        <h2 class="m-tt">{{ $t("add.添加排放源") }}</h2>
        <div style="margin-left: -40px">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item :label="$t('add.排放源名称')" prop="name">
                <el-input
                  v-model="deviceInfoForm.name"
                  :placeholder="$t('global.请输入')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('add.排放源类型')" prop="typeId">
                <el-select
                  filterable
                  v-model="deviceInfoForm.typeId"
                  :placeholder="$t('global.请选择')"
                >
                  <el-option
                    v-for="item in emissionsEnums"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('add.部门')" prop="orgId">
                <el-cascader
                  v-model="deviceInfoForm.orgId"
                  :options="deptTree"
                  :props="{
                    label: 'orgName',
                    value: 'id',
                    children: 'childrenList',
                    checkStrictly: true,
                    emitPath: false,
                  }"
                  :placeholder="$t('global.请选择')"
                  clearable
                  @change="handleChangeDept"
                  :show-all-levels="false"
                ></el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item :label="$t('add.型号')" prop="model">
                <el-input
                  v-model="deviceInfoForm.model"
                  :placeholder="$t('global.请输入')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('add.所在地')" prop="storageAddress">
                <el-input
                  v-model="deviceInfoForm.storageAddress"
                  :placeholder="$t('global.请输入')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('add.负责人')" prop="principalId">
                <el-select
                  v-model="deviceInfoForm.principalId"
                  :placeholder="$t('global.请选择')"
                >
                  <el-option
                    v-for="item in userEnums"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item :label="$t('add.状态')" prop="status">
                <el-radio-group
                  v-model="deviceInfoForm.status"
                  :disabled="isModeInfo"
                >
                  <el-radio :label="0">{{ $t("global.启用") }}</el-radio>
                  <el-radio :label="1">{{ $t("global.停用") }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('add.内容')" prop="remark">
                <el-input
                  type="textarea"
                  :rows="5"
                  resize="none"
                  maxlength="300"
                  show-word-limit
                  v-model="deviceInfoForm.remark"
                  :placeholder="$t('global.请输入')"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <h2 class="m-tt">
          碳排放计算计算方法
          <span>
            <el-radio-group
              v-model="deviceInfoForm.countType"
              @change="changeCountType"
            >
              <el-radio :label="1">碳质量平衡法</el-radio>
              <el-radio :label="2">因子法</el-radio>
            </el-radio-group>
          </span>
        </h2>
        <div style="margin-left: 0px">
          <div class="g-form-add-box">
            <div>
              <el-radio-group
                v-model="factorType"
                size="small"
                v-if="deviceInfoForm.countType == 1"
              >
                <el-radio-button label="1" plain
                  >原材料<span class="required">*</span></el-radio-button
                >
                <el-radio-button label="2" plain
                  >产出物<span class="required">*</span></el-radio-button
                >
                <el-radio-button label="3" plain>含碳废弃物</el-radio-button>
              </el-radio-group>
              <el-button
                type="primary"
                @click="handleAddRow"
                size="small"
                style="margin-left: 20px"
                >增行</el-button
              >
            </div>
            <el-form-item label-width="30px" prop="emissionsFactorList">
              <div>
                <el-table
                  :data="
                    emissionsFactorList.filter(
                      (ele) => ele.emissionsFactorType == factorType
                    )
                  "
                >
                  <el-table-column
                    prop="consumeMaterial"
                    label="材料名称"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <div style="text-align: center">
                        {{ scope.row.consumeMaterial }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="consumeMaterialUnit"
                    label="材料单位"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <div style="text-align: center">
                        {{ scope.row.consumeMaterialUnit }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="factorLevel"
                    label="因子"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <div style="text-align: center">
                        {{ scope.row.factor }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    label="因子单位"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <div style="text-align: center">
                        {{ scope.row.factorUnit }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="dataSource"
                    label="数据来源"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <div style="text-align: center">
                        {{ scope.row.dataSource }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="maximumValue"
                    width="120"
                    label="活动水平最大值"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.maximumValue"
                        placeholder="请输入"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="minimumValue"
                    width="120"
                    label="活动水平最小值"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.minimumValue"
                        placeholder="请输入"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="activityLevelId"
                    width="120"
                    label="活动水平不确定性评分"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-select
                        filterable
                        v-model="scope.row.activityLevelId"
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="item in factorActivityLevelTypeEnums"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="frequencyId"
                    width="120"
                    label="校正频率评分"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-select
                        filterable
                        v-model="scope.row.frequencyId"
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="item in factorFrequencyTypeEnums"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="qualityLevelId"
                    label="因子等级"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-select
                        filterable
                        v-model="scope.row.qualityLevelId"
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="item in factorQualityLevelEnums"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="factorDescription"
                    label="备注"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <div style="text-align: center">
                        {{ scope.row.factorDescription }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                      <el-button type="text" size="small">
                        <span
                          @click="handleAddSelfTest(scope.row, scope.$index)"
                          >自测值</span
                        >
                      </el-button>
                      <el-button
                        @click="handleRemoveRow(scope.row, scope.$index)"
                        type="text"
                        size="small"
                      >
                        <span style="color: #f56c6c">删除</span>
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div class="m-btn-group">
      <el-button
        class="u-btn"
        size="small"
        type="primary"
        @click="submitForm('deviceInfoForm')"
        v-if="!isModeInfo"
        :loading="buttonloading"
      >
        提交
      </el-button>
      <el-button
        class="u-btn s-cancel"
        size="small"
        v-if="!isModeInfo"
        @click="returnPreviousPage"
      >
        返回
      </el-button>
      <el-button
        class="u-btn s-cancel"
        size="small"
        v-else
        @click="$router.go(-1)"
      >
        返回
      </el-button>
    </div>
    <my-dialog ref="myDialog"></my-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import api from '@/api'
import base from '@/templates/add'
import tool from '@/tool'
import MyDialog from './dialog'

export default Vue.extend({
  components: {
    MyDialog,
  },
  directives: {},
  filters: {},
  extends: base,
  props: {},
  data () {
    return {
      emissionsEnums: [],
      factorActivityLevelTypeEnums: [],
      factorFrequencyTypeEnums: [],
      factorQualityLevelEnums: [],
      deptTree: [],
      userEnums: [],
      tempRow: {},
      tempIndex: '',
      deviceInfoForm: {
        name: '',
        model: '',
        status: 0,
        countType: 1,
        principalId: ''
      },
      factorType: '1',
      emissionsFactorList: [],
      emissionsFactorList_back: [],
      countType_back: '',
      buttonloading: false,
      loading: true,
      rules: {
        name: [
          { required: true, message: '必填', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ],
        typeId: [
          { required: true, message: '必填', trigger: 'change' }
        ],
        orgId: [
          { required: true, message: '必填', trigger: 'change' }
        ],
        model: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        storageAddress: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        principalId: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        emissionsFactorList: [
          {
            required: true,
            validator: (rule, value, callback) => {
              console.log('emissionsFactorList', this.emissionsFactorList)
              if (this.emissionsFactorList.length === 0) {
                callback(new Error('请至少填报一项项目'))
              } else if (this.emissionsFactorList.every(ele => {
                var flag = true
                if (!ele.frequencyId || !ele.qualityLevelId || !ele.activityLevelId || !ele.maximumValue || !ele.minimumValue) {
                  flag = false
                }
                return flag
              })) {
                callback()
              } else {
                callback(new Error('请完整的填报项目'))
              }
            }
          }
        ]
        //   fileList: [
        //     {
        //       validator: (rule, value, callback) => {
        //         console.log('value', value)
        //         if (this.deviceInfoForm.fileList.length === 0) {
        //           callback(new Error('请上传文件'))
        //         } else {
        //           callback()
        //         }
        //       },
        //       required: true,
        //       trigger: 'blur'
        //     }]
      }
    }
  },
  computed: {
    isModeAdd () {
      return !this.$route.params.id
    },
    isModeEdit () {
      return !!this.$route.params.id
    },
    isModeInfo () {
      return false
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.init()
  },
  beforeMount () {
  },
  mounted () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    init () {
      api.auth.queryOptionTypeList({ type: 'emissionsType' }).then(res => {
        this.emissionsEnums = res.data.map(ele => ({ label: ele.name, value: ele.id }))
      })

      api.auth.queryOptionTypeList({ type: 'factorActivityLevelType' }).then(res => {
        this.factorActivityLevelTypeEnums = res.data.map(ele => ({ label: ele.name, value: ele.id }))
      })
      api.auth.queryOptionTypeList({ type: 'factorFrequencyType' }).then(res => {
        this.factorFrequencyTypeEnums = res.data.map(ele => ({ label: ele.name, value: ele.id }))
      })
      api.auth.queryOptionTypeList({ type: 'factorQualityLevel' }).then(res => {
        this.factorQualityLevelEnums = res.data.map(ele => ({ label: ele.name, value: ele.id }))
      })

      api.organization.queryOrgTree({}).then(res => {
        console.log('res', res)
        var tree = tool.treearr(res.data, true)
        this.deptTree = tree
      })

      if (this.isModeAdd) {
        this.loading = false
      } else if (this.isModeEdit) {
        this.getInfo()
      }
    },
    handleChangeDept (orgs) {
      var orgId = orgs
      this.deviceInfoForm.principalId = ''
      api.user.queryUserList({ orgId: orgId }).then(res => {
        this.userEnums = res.data.map(ele => ({ label: ele.userName, value: ele.id }))
      })
    },
    handleChangeUser (id) {
      var obj = this.userEnums.find(ele => ele.id === id)
      if (obj) {
        this.deviceInfoForm.phone = obj.phone
      }
    },
    changeCountType (countType) {
      if (countType == this.countType_back) {
        var aa
        if (this.deviceInfoForm.countType == 1) {
          aa = this.emissionsFactorList_back
        } else {
          aa = this.emissionsFactorList_back.filter(ele => ele.emissionsFactorType == 1)
        }
        this.emissionsFactorList = aa
      } else {
        this.emissionsFactorList = []
      }
    },
    handleAddRow () {
      this.$refs.myDialog.show()
    },
    handleAddSelfTest (row, index) {
      this.tempRow = row
      this.$refs.myDialog2.show(row.emissionsSelfTestValueList)
    },
    handleAddSelfTestOk (list) {
      this.tempRow.emissionsSelfTestValueList = list
      console.log('tableData', this.emissionsFactorList)
    },
    handleAddRowOk (row) {
      row.emissionsFactorType = this.factorType
      this.emissionsFactorList.push(row)
      console.log('emissionsFactorList', this.emissionsFactorList)
    },
    handleRemoveRow (item) {
      var index = this.emissionsFactorList.findIndex(ele => ele.id == item.id)
      this.emissionsFactorList.splice(index, 1)
    },
    getInfo () {
      api.emission.findById({
        id: this.$route.params.id
      }).then(res => {
        this.deviceInfoForm = res.data
        this.emissionsFactorList = res.data.emissionsFactorVOS
        this.emissionsFactorList_back = res.data.emissionsFactorVOS
        this.countType_back = res.data.countType
        api.user.queryUserList({ orgId: res.data.orgId }).then(res => {
          this.userEnums = res.data.map(ele => ({ label: ele.userName, value: ele.id }))
        })
      }).finally(() => {
        this.loading = false
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.buttonloading = true
          // var timeString = this.$moment(this.deviceInfoForm.visitTime).format('YYYY-MM-DD HH:mm:ss')
          // var picUrl = this.imageUrl
          this.deviceInfoForm.emissionsFactorList = this.emissionsFactorList
          this.deviceInfoForm.emissionsFactorList.forEach(ele => {
            if (!ele.factorId) {
              ele.factorId = ele.id
              delete ele.id
            }
          })
          var query = {
            ...this.deviceInfoForm
          }
          delete query.emissionsFactorVOS
          console.log('query', query)

          // if (2 > 1) {
          //   return
          // }
          if (this.isModeAdd) {
            api.emission.save(query).then(() => {
              this.buttonloading = false

              this.$router.go(-1)
            }).catch(_ => {
              this.buttonloading = false
            })
          }
          // 编辑
          if (this.isModeEdit) {
            api.emission.update({
              ...{ id: this.$route.params.id },
              ...query
            }).then(() => {
              this.buttonloading = false

              this.$router.go(-1)
            }).catch(_ => {
              this.buttonloading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
})
</script>
